import React from 'react'
import { Link } from 'gatsby'
import Header from '../components/Header'
import { rhythm, scale } from '../utils/typography'

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props

    return (
      <div
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      > 
        <header style={{ marginBottom: `1.5rem` }}>
          <ul style={{ listStyle: `none`, float: `right` }}>
            <ListLink to="/">Blog</ListLink>
            <ListLink to="/about/">About</ListLink>
            <ListLink to="/contact/">Contact</ListLink>
          </ul>
        </header>
        <Header title={title} location={location}/>
        {children}
      </div>
    )
  }
}

export default Layout
